@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'GalanoGrotesque-Medium'; /*Can be any text*/
  src: local('GalanoGrotesque-Medium'),
    url('./assets/font/GalanoGrotesque-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'GalanoGrotesque-Regular'; /*Can be any text*/
  src: local('GalanoGrotesque-Regular'),
    url('./assets/font/GalanoGrotesque-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GalanoGrotesque-SemiBold'; /*Can be any text*/
  src: local('GalanoGrotesque-SemiBold'),
    url('./assets/font/GalanoGrotesque-SemiBold.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

@media only screen and (min-width: 600px) {
  body {
    z-index: 100;
    background-color: white;
  }
}

h1 {
  font-family: GalanoGrotesque-SemiBold;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

h2 {
  font-family: GalanoGrotesque-SemiBold;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

h3 {
  font-family: GalanoGrotesque-SemiBold;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

h4 {
  font-family: GalanoGrotesque-Medium;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin: 0 !important;
}

h5 {
  font-family: GalanoGrotesque-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

h6,
.text-gray-800 {
  font-family: GalanoGrotesque-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

p,
.text-xs {
  font-family: GalanoGrotesque-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.form-group label {
  font-family: GalanoGrotesque-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-group input,
.form-group select,
.form-group textarea {
  font-family: GalanoGrotesque-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.active {
  color: var(--light-blue) !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

a {
  color: black !important;
  text-decoration: none !important;
  fill: var(--light-blue) !important;
  text-align: center !important;
}
.badge {
  width: auto;
  padding: 25px solid transparent;
  background: var(--light-blue);
  color: white;
}
.form-group label {
  font-size: 1.125rem !important;
}
:root {
  --primary-color: #d39e54;
  --secondary-color: #fcf2e4;
  --red: #e42434;
  --orange: #f9bb26;
  --green: #4cbc14;
}

.nav-item .dropdown .no-arrow .d-sm-none {
  color: red !important;
}
